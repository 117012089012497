import { useQuery } from "react-query";
import axiosFetch from "../configs/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { setEbook } from "../redux-slice/ebookSlice";

const fetchBook = async () => {
  const response = await axiosFetch().get("/book");
  return response.data;
};
const useFetchBook = () => {
  const book = useSelector((state) => state.ebook);
  const dispatch = useDispatch();
  const { data, isLoading, error, isError, isSuccess, isFetched } = useQuery(
    "book",
    fetchBook,
    {
      enabled: book.length === 0,
      onSuccess: (data) => {
        dispatch(setEbook(data));
      },
    },
  );
  return {
    data: book.length > 0 ? book : data,
    isLoading,
    error,
    isError,
    isSuccess,
    isFetched,
  };
};

export default useFetchBook;
