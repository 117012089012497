import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const ebookSlice = createSlice({
  name: "ebook",
  initialState,
  reducers: {
    setEbook: (state, actions) => {
      return actions.payload;
    },
    ebookDiscount: (state, actions) => {
      const { price } = actions.payload;
      const updatedCoupons = state.map((item) => {
        return { ...item, price };
      });
      console.log(updatedCoupons);
      return updatedCoupons;
    },
  },
});

export const { ebookDiscount, setEbook } = ebookSlice.actions;

export default ebookSlice.reducer;
